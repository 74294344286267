import { PAGE_URLS } from '~/config/page-url'
import { useHotMatchApi } from '~/api/hotmatch'
import { NCC_TYPES } from '~/constants/the-thao/schedule'

export const useSportNavigation = () => {
  const { $axios, $device, $config } = useNuxtApp()
  const router = useRouter()
  const {
    GET_LINK_C_SPORT,
    GET_LINK_K_SPORT,
    GET_LINK_S_SPORT,
    GET_LINK_VIRTUAL,
    GET_LINK_E_SPORT,
    GET_LINK_M_SPORT,
    GET_LINK_P_SPORT,
    GET_LINK_T_SPORT
  } = useHotMatchApi()

  interface Payload {
    params?: {
      matchId?: string | number
      leagueId?: string | number
      live?: string | boolean
      loginPath?: string
      registerPath?: string
      utm_source?: string
    }
  }

  const getLink = async (api: string, payload?: Payload): Promise<any> => {
    try {
      return await $axios.get(api, payload)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const getUrlNccSport = async (item: any, ncc: string): Promise<any> => {
    const payload: Payload = {
      params: {
        matchId: item?.match_id,
        leagueId: item?.league_id,
        live: item?.live,
        loginPath: '?popup=modalLogin',
        registerPath: '?popup=modalRegister',
        utm_source: $config.public.DOMAIN_URL
      }
    }

    switch (ncc) {
      case NCC_TYPES.KSPORTS:
        return await getLink(GET_LINK_K_SPORT, payload)
      case NCC_TYPES.CSPORTS:
        return await getLink(GET_LINK_C_SPORT, payload)
      case NCC_TYPES.SSPORTS:
        return await getLink(GET_LINK_S_SPORT, payload)
      case NCC_TYPES.MSPORTS:
        return await getLink(GET_LINK_M_SPORT, payload)
      case NCC_TYPES.TSPORTS:
        return await getLink(GET_LINK_T_SPORT, payload)
      case NCC_TYPES.VIRTUAL_SPORTS:
        return await getLink(GET_LINK_VIRTUAL)
      case NCC_TYPES.ESPORTS:
        return await getLink(GET_LINK_E_SPORT)
      case NCC_TYPES.PSPORTS:
        return await getLink(GET_LINK_P_SPORT)
      default:
        return ''
    }
  }

  const playSport = async (item: any, ncc: string, newTab: boolean): Promise<void> => {
    try {
      const matchId = ncc === NCC_TYPES.KSPORTS ? item.match_id || item.ksport_match_id : item.athena_match_id
      const leagueId = ncc === NCC_TYPES.KSPORTS ? item.league_id || item.ksport_league_id : item.athena_league_id
      const live = item.live || ''
      const path = `/${ncc}`

      if ($device.isMobileOrTablet) {
        const openNewTab = window.open('about:blank', '_blank')
        const { data: res } = await getUrlNccSport({ match_id: matchId, league_id: leagueId, live }, ncc)
        if (res && res.status === 'OK') {
          if (res.data.maintenance) {
            openNewTab!.location.href = PAGE_URLS.MAINTAIN
            return
          }
          openNewTab!.location.href = res.data.url
        } else {
          openNewTab!.close()
        }
      } else if (newTab) {
        const routeData = router.resolve({
          path,
          query: { match_id: matchId, league_id: leagueId, live }
        })
        window.open(routeData.href, '_blank')
      } else {
        router.push({
          path,
          query: { match_id: matchId, league_id: leagueId, live }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getUrlNccSport,
    playSport
  }
}
